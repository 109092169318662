import { Button, Spinner } from '@portal/ui';
import { Slot } from 'graphql/types';
import { useState } from 'react';
export interface TimeSlotsProps {
  onConfirm: (value: string) => void;
  timeSlots?: Slot[];
  loading?: boolean;
}

function TimeSlots({ onConfirm, timeSlots, loading }: TimeSlotsProps) {
  const [activeSlot, setActiveSlot] = useState<number | null>(null);
  const handleClick = (index: number) => {
    setActiveSlot(index);
  };
  return loading ? (
    <div className="relative flex justify-around items-center w-full h-1/2">
      <Spinner />
    </div>
  ) : (
    <>
      {timeSlots && timeSlots.length > 0 ? (
        timeSlots?.map((timeslot, index) => (
          <div className={`flex ${activeSlot === index ? 'justify-between gap-1' : 'space-y-2 my-3'}`}>
            <div
              onClick={() => handleClick(index)}
              className={`flex h-12 justify-center items-center focus:!outline-none focus:!ring-0 focus:!ring-offset-none ${
                activeSlot === index
                  ? `w-2/4 border border-primary-600 rounded-lg bg-primary-50`
                  : 'w-32 border border-steel-300 rounded-lg md:w-full md:h-[52px]  cursor-pointer hover:border-2 hover:border-primary-700'
              }`}
            >
              <Button displayType="text" title="time slot">
                <span
                  className={`font-inter ${
                    activeSlot === index ? 'text-primary-600' : ''
                  } font-medium text-sm leading-5`}
                >
                  {timeslot.startTime}
                </span>
              </Button>
            </div>
            {activeSlot === index && (
              <div
                onClick={() => onConfirm(timeslot.startTime)}
                className={`flex w-2/4 [&>button]:!text-white h-12 justify-center items-center focus:!outline-none border-1 border-primary-600 rounded-lg bg-primary-600 focus:!ring-0 focus:!ring-offset-none`}
              >
                <Button displayType="text" title="confirm">
                  Confirm
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="text-sm text-steel-600 mt-5 font-medium text-center">No slots available</p>
      )}
    </>
  );
}

export default TimeSlots;
