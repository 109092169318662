export const NotFoundPage = () => (
  <div className="flex flex-col items-center justify-center h-screen">
    <div className="font-bold text-primary mb-3">404</div>
    <div className="text-4xl font-bold text-gray-700 mb-3">Page not found</div>
    <div className="text-gray-700 sm:w-full md:w-1/3  text-center">
      The page you&apos;re looking for couldn&apos;t be found. This could be because the URL is incorrect or the page
      has been removed.
    </div>
  </div>
);
