import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { client } from 'libs/apollo';
import { store } from 'redux/store';
import { Provider as ReduxProvider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <ReduxProvider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </ReduxProvider>
  </ApolloProvider>
);
