export enum DateFormats {
  MONTH_NAME_WITH_YEAR = "MMMM YYYY", // April 2023
  DAY_OF_MONTH_WITH_YEAR = "D MMMM YYYY", // 5 April 2023
  MONTH_DAY_WITH_YEAR = "MMM D, YYYY", // Apr 5, 2023
  WEEKDAY_NAME_SHORTER = "ddd", // Tue
  WEEKDAY_FULL_NAME = "dddd", // Sunday
  DAY_OF_MONTH = "D", // 5
  SHORT_ABBREVIATED_WEEKDAY = "dd", // Tu
  HOURS_MINS_WITH_AMPM = "hh:mm A", // 09:34 PM
  HOURS_MINS = "HH:mm", // 09:34
  HOURS_WItH_AMPM = "hh a", // 09 pm
  COMPLETE_DATE = "YYYY-MM-DD", // "2023-04-11"
  DAY_MONTH_YEAR = "DD/MM/YYYY", // "11/04/2023"
  STANDARD_ISO_FORMAT = "YYYY-MM-DDTHH:mm", // "2023-04-11T03:42"
}
