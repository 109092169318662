import * as Yup from 'yup';

export const appointmentSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  notes: Yup.string(),
  questions: Yup.array().of(
    Yup.object().shape({
      isRequired: Yup.boolean(),
      answer: Yup.string().when('isRequired', {
        is: true,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
    })
  ),
});
