import { Button } from '@portal/ui';
import EventCard from 'components/eventCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'redux/hooks';

function HomePage() {
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.config);
  const { slug } = useParams();
  return (
    <div className="h-screen">
      <div className="border-none flex flex-col justify-center min-h-screen gap-4 m-auto w-3/4">
        <div className="flex shadow-sm justify-evenly items-center p-10 flex-col min-h-[500px] bg-white rounded-lg">
          <>
            <div className="text-center flex items-center flex-col">
              <img src={data?.merchantAccountLogo ?? ''} className="w-20 h-20" />
              <p className="font-medium text-md my-2 text-steel-600">{data?.title}</p>
              <p className="text-sm font-normal relative break-words text-center text-steel-600 w-3/4">
                {data?.welcomeMessage}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4 mx-20">
              {data?.eventTypes.map((event) => (
                <Button
                  displayType="text"
                  key={event.id}
                  onClick={() =>
                    navigate(`/${slug}/appointment/${event.slug}`, {
                      state: {
                        ...event,
                      },
                    })
                  }
                  title={event.name}
                >
                  <EventCard {...event} />
                </Button>
              ))}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
