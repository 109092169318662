import CalendarView from 'components/calendarView';
import TimeSlots from 'components/timeSlots';
import { getLocalTimeZone } from '@internationalized/date';
import { useEffect, useMemo, useState } from 'react';
import { DateValue } from 'react-aria';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { DateFormats } from '@portal/utils/dates';
import { useGetSlotsLazyQuery } from 'graphql/query.generated';
import AppointmentInfo from 'components/appointmentInfo';
import { useSelector } from 'redux/hooks';
import { BookingConfigurationAvailabilityDay } from 'graphql/types';
function AppointmentDetailsPage() {
  const [date, setDate] = useState<Date | null>(new Date());
  const { state } = useLocation();
  const config = useSelector((state) => state.config);
  const { slug, eventSlug } = useParams();
  const [timezone, setTimezone] = useState<string>(config?.data?.tzName ?? '');
  const [fetchSlots, { data, loading, error }] = useGetSlotsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const navigate = useNavigate();
  const onDateChange = (value: DateValue) => {
    setDate(value.toDate(getLocalTimeZone()));
  };
  const handleTimeConfirmation = (value: string) => {
    navigate(`/${slug}/appointment/${eventSlug}/form`, {
      state: {
        ...state,
        time: value,
        date: moment(date).format(DateFormats.COMPLETE_DATE),
        timezone,
      },
    });
  };
  const availability = useMemo(() => {
    if (date) {
      const day = moment(date).format('ddd').toLowerCase();
      return config?.data?.availability.find(
        (item) => item.day === ((day === 'thu' ? 'thr' : day) as BookingConfigurationAvailabilityDay)
      );
    }
  }, [date]);

  useEffect(() => {
    if (availability && eventSlug) {
      const startTime = moment(date)
        .set('hours', moment(availability.startTime, 'HH:mm').hours())
        .set('minutes', moment(availability.startTime, 'HH:mm').minutes())
        .toISOString();
      const endTime = moment(date)
        .set('hours', moment(availability.endTime, 'HH:mm').hours())
        .set('minutes', moment(availability.endTime, 'HH:mm').minutes())
        .toISOString();
      fetchSlots({
        variables: {
          startTime,
          endTime,
          eventTypeSlug: config.data?.eventTypes.find((e) => e.slug === eventSlug)?.slug as string,
          bookingConfigurationSlug: slug as string,
        },
      });
    }
  }, [availability, eventSlug]);
  const timeSlots = useMemo(() => {
    if (data?.findAllSlots) {
      return data.findAllSlots.map((slot) => {
        return {
          ...slot,
          startTime: moment(slot.startTime).format('h:mm A'),
          endTime: moment(slot.endTime).format('h:mm A'),
        };
      });
    }
  }, [data, data?.findAllSlots]);

  const handleTimezoneChange = (value: string) => {
    setTimezone(value);
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-steel-200">
      <div className="p-10 rounded-xl bg-white flex flex-col md:flex-row my-6 md:space-x-6 min-h-screen  min-w-[1000px]">
        <div className="flex items-center">
          <div className="h-full border-r border-steel-200">
            <AppointmentInfo
              description={state.description}
              heading={state.name}
              time={state.duration}
              title={config?.data?.title ?? ''}
            />
          </div>
        </div>
        <CalendarView
          maxDate={moment().add(state.dayAvailabilityInFuture, 'days').format(DateFormats.COMPLETE_DATE)}
          timezone={timezone}
          handleTimezoneChange={handleTimezoneChange}
          onDateChange={onDateChange}
        />
        <div className="flex flex-col p-2 m-3 w-full">
          <div className="flex items-start">
            <span className="font-inter font-medium text-sm text-steel-600 my-2">
              {date ? moment(date).format(DateFormats.MONTH_DAY_WITH_YEAR) : ''}
            </span>
          </div>
          <TimeSlots timeSlots={timeSlots || []} onConfirm={handleTimeConfirmation} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default AppointmentDetailsPage;
