import { PropsWithChildren, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchConfig } from 'redux/thunks/config';
import { useDispatch, useSelector } from 'redux/hooks';
import { Spinner } from '@portal/ui';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { data, loading } = useSelector((state) => state.config);

  useEffect(() => {
    if (slug) {
      dispatch(fetchConfig(slug));
    }
  }, [slug]);

  useEffect(() => {
    if (!slug || !data) {
      navigate('/404');
    }
  }, [slug, data]);
  return <>{loading ? <Spinner /> : children}</>;
};

export default Layout;
