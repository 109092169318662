import { useCalendarGrid } from '@react-aria/calendar';
import { CalendarState } from 'react-stately';
import { getWeeksInMonth, DateDuration, today, getLocalTimeZone } from '@internationalized/date';
import { useLocale } from '@react-aria/i18n';
import { CalendarCell } from './CalendarCell';

export interface CalendarGridProps {
  state: CalendarState;
  offset?: DateDuration;
}

export function CalendarGrid({ state, offset = {} }: CalendarGridProps) {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start.add(offset);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate: today(getLocalTimeZone()),
    },
    state
  );

  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className="text-gray-600">
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? <CalendarCell key={i} state={state} date={date} currentMonth={startDate} /> : <td key={i} />
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
