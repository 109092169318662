import { Routes, Route } from 'react-router-dom';
import HomePage from 'views/HomePage/HomePage';
import { AppointmentDetailsPage, AppointmentForm, ConfirmationPage } from 'views/Appointments';
import { NotFoundPage } from 'views/Errors/NotFound';
import Layout from 'components/layout';
const AppRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/:slug?"
        element={
          <Layout>
            <HomePage />
          </Layout>
        }
      />
      <Route
        exact
        path="/:slug/appointment/:eventSlug"
        element={
          <Layout>
            <AppointmentDetailsPage />
          </Layout>
        }
      />
      <Route
        exact
        path="/:slug/appointment/:eventSlug/form"
        element={
          <Layout>
            <AppointmentForm />
          </Layout>
        }
      />
      <Route
        exact
        path="/:slug/appointment/:eventSlug/confirmed"
        element={
          <Layout>
            <ConfirmationPage />
          </Layout>
        }
      />
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
