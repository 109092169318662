import { Form, FieldArray, FormikValues } from 'formik';
import { appointmentSchema } from 'components/validation/appointmentSchema';
import { Button, Formik, TextField } from '@portal/ui';
import AppointmentInfo from 'components/appointmentInfo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import { EventTypeQuestion } from 'graphql/types';
import { useBookSlotMutation } from 'graphql/mutation.generated';
import moment from 'moment';
import toast from '@portal/ui/components/widgets/Toast/notify';
interface Question {
  answer: string;
  isRequired: boolean;
}
type FormState = {
  name: string;
  email: string;
  notes: string;
  questions: Question[];
};
function AppointmentForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [bookSlot, { loading }] = useBookSlotMutation({
    onCompleted: () => {
      toast.success('Appointment Confirmed');
    },
    onError: (err) => {
      toast.error(err.message ?? 'Something went wrong');
    },
  });

  const { slug, eventSlug } = useParams();
  const config = useSelector((state) => state.config);

  const handleSlotBooking = (values: FormikValues) => {
    bookSlot({
      variables: {
        data: {
          answers: values.questions.map((question: { id: string; answer: string }) => ({
            questionId: question.id,
            text: question.answer,
          })),
          name: values.name,
          email: values.email,
          notes: values.notes,
          startTime: moment(state.date)
            .set('hours', moment(state.time, 'HH:mm').hours())
            .set('minutes', moment(state.time, 'HH:mm').minutes())
            .toISOString(),
          locationType: 'PHONE_CALL',
          eventTypeSlug: config?.data?.eventTypes.find((e) => e.slug === eventSlug)?.slug as string,
          bookingConfigurationSlug: slug as string,
        },
      },
    }).then((res) => {
      if (!res.errors)
        navigate(`/${slug}/appointment/${eventSlug}/confirmed`, {
          state: {
            ...state,
            eventName: state.name,
            ...values,
          },
        });
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div className="p-4 rounded-xl bg-white flex flex-col md:flex-row my-6 md:space-x-6 md:w-3/4">
        <AppointmentInfo
          description={state.description}
          heading={state.name}
          time={state.duration}
          title={config?.data?.title ?? ''}
        />
        <div className="flex items-center">
          <div className="h-full border-l border-gray-250"></div>
        </div>
        <div className="flex flex-col w-2/3">
          <span className="font-inter my-2 mt-6 font-bold text-base leading-6 text-[#4A5578]">Enter details</span>
          <Formik<FormState>
            initialValues={{
              name: '',
              email: '',
              notes: '',
              questions: state.questions.map((question: EventTypeQuestion) => ({
                id: question.id,
                answer: '',
                isRequired: question.required,
              })),
            }}
            validationSchema={appointmentSchema}
            onSubmit={handleSlotBooking}
          >
            {({ errors }) => (
              <Form className="w-2/3 flex flex-col gap-2">
                <TextField id="name" label="Name" />
                <TextField id="email" label="Email" />
                <FieldArray name="answers">
                  {() => (
                    <>
                      {state.questions.map((question: EventTypeQuestion, index: number) => (
                        <div key={question.id}>
                          <TextField
                            label={question.text}
                            id={`questions[${index}].answer`}
                            forceHasError={errors.questions ? !!errors.questions[index] : false}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <TextField id="notes" label="Notes" type="textarea" />
                <div className="mt-2">
                  <Button loading={loading} title="schedule" displayType="primary" type="submit">
                    Schedule
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AppointmentForm;
