/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchConfigurationQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
}>;


export type FetchConfigurationQuery = { __typename?: 'Query', config?: { __typename?: 'BookingConfiguration', id: string, slug: string, title: string, welcomeMessage: string, merchantAccountName: string, merchantAccountLogo?: string | null, tzName: string, availability: Array<{ __typename?: 'BookingConfigurationAvailability', id: string, day: Types.BookingConfigurationAvailabilityDay, enabled: boolean, startTime: string, endTime: string }>, eventTypes: Array<{ __typename?: 'EventType', id: string, name: string, slug: string, description: string, color: string, duration: number, location?: string | null, dayAvailabilityInFuture: number, questions: Array<{ __typename?: 'EventTypeQuestion', id: string, text: string, required: boolean }> }> } | null };

export type GetSlotsQueryVariables = Types.Exact<{
  bookingConfigurationSlug: Types.Scalars['String'];
  eventTypeSlug: Types.Scalars['String'];
  startTime: Types.Scalars['String'];
  endTime: Types.Scalars['String'];
}>;


export type GetSlotsQuery = { __typename?: 'Query', findAllSlots: Array<{ __typename?: 'Slot', startTime: string, endTime: string }> };


export const FetchConfigurationDocument = gql`
    query fetchConfiguration($slug: String!) {
  config(slug: $slug) {
    id
    slug
    title
    welcomeMessage
    merchantAccountName
    merchantAccountLogo
    tzName
    availability {
      id
      day
      enabled
      startTime
      endTime
    }
    eventTypes {
      id
      name
      slug
      description
      color
      duration
      location
      dayAvailabilityInFuture
      questions {
        id
        text
        required
      }
    }
  }
}
    `;

/**
 * __useFetchConfigurationQuery__
 *
 * To run a query within a React component, call `useFetchConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConfigurationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useFetchConfigurationQuery(baseOptions: Apollo.QueryHookOptions<FetchConfigurationQuery, FetchConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchConfigurationQuery, FetchConfigurationQueryVariables>(FetchConfigurationDocument, options);
      }
export function useFetchConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchConfigurationQuery, FetchConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchConfigurationQuery, FetchConfigurationQueryVariables>(FetchConfigurationDocument, options);
        }
export type FetchConfigurationQueryHookResult = ReturnType<typeof useFetchConfigurationQuery>;
export type FetchConfigurationLazyQueryHookResult = ReturnType<typeof useFetchConfigurationLazyQuery>;
export type FetchConfigurationQueryResult = Apollo.QueryResult<FetchConfigurationQuery, FetchConfigurationQueryVariables>;
export const GetSlotsDocument = gql`
    query GetSlots($bookingConfigurationSlug: String!, $eventTypeSlug: String!, $startTime: String!, $endTime: String!) {
  findAllSlots(
    bookingConfigurationSlug: $bookingConfigurationSlug
    eventTypeSlug: $eventTypeSlug
    startTime: $startTime
    endTime: $endTime
  ) {
    startTime
    endTime
  }
}
    `;

/**
 * __useGetSlotsQuery__
 *
 * To run a query within a React component, call `useGetSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlotsQuery({
 *   variables: {
 *      bookingConfigurationSlug: // value for 'bookingConfigurationSlug'
 *      eventTypeSlug: // value for 'eventTypeSlug'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
      }
export function useGetSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlotsQuery, GetSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlotsQuery, GetSlotsQueryVariables>(GetSlotsDocument, options);
        }
export type GetSlotsQueryHookResult = ReturnType<typeof useGetSlotsQuery>;
export type GetSlotsLazyQueryHookResult = ReturnType<typeof useGetSlotsLazyQuery>;
export type GetSlotsQueryResult = Apollo.QueryResult<GetSlotsQuery, GetSlotsQueryVariables>;