import { Icons } from '@portal/ui';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'redux/hooks';

const formatDate = (inputDate: string) => {
  const dateObj = moment(inputDate, 'YYYY-MM-DDTHH:mm A');

  const startTime = dateObj.format('h:mm A');

  const endTime = dateObj.clone().add(30, 'minutes').format('h:mm A');

  const dayOfWeek = dateObj.format('dddd');
  const dayOfMonth = dateObj.format('D');
  const month = dateObj.format('MMMM');
  const year = dateObj.format('YYYY');
  const result = `${startTime} to ${endTime}, ${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;

  return result;
};

function ConfirmationPage() {
  const { state } = useLocation();
  const config = useSelector((state) => state.config);
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div className="p-4 rounded-xl bg-white flex flex-col md:flex md:items-center md:justify-center my-6 md:space-x-6 w-3/4 ">
        <div className="flex flex-col items-center space-y-4 mb-4 gap-x-0 px-0 mx-0 w-[388px] pt-[136px] pb-[128px]">
          <img src={config?.data?.merchantAccountLogo ?? ''} className="w-20 h-20" />
          <span className="font-inter font-medium text-gray-900 text-lg leading-7">Confirmed</span>
          <p className="block text-center break-normal ">
            You&apos;re scheduled with {config?.data?.merchantAccountName ?? ''}. A calendar invite has been sent to
            your email address
          </p>
          <div className="flex flex-col justify-start mx-0 px-0 gap-3">
            <div className="flex my-8  items-center  gap-2 mb-0 pb-0">
              <div className="flex w-6 h-6  rounded-xl" style={{ background: state.color }}></div>
              <p className="flex text-xl font-medium font-inter text-steel-900">{state.eventName}</p>
            </div>
            <div className={`flex flex-col items-start gap-3 group my-2'}`}>
              <div className="flex gap-2 items-center ">
                <Icons.Clock width={18} height={18} />{' '}
                <p className="text-sm text-steel-900 whitespace-nowrap">{formatDate(`${state.date}T${state.time}`)}</p>
              </div>
              <div className="flex gap-2 items-center text-sm text-steel-900">
                <Icons.Globe /> <p>{state.timezone}</p>
              </div>
              <div className="flex gap-2 items-center text-sm text-steel-900">
                <Icons.Location /> <p>{state.location}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPage;
