import { Icons } from '@portal/ui';
import React from 'react';

interface AppointmentInfoProps {
  title: string;
  heading: string;
  time: number;
  description: string;
}

const AppointmentInfo: React.FC<AppointmentInfoProps> = ({ title, heading, time = 30, description }) => {
  return (
    <div className="my-2 py-3 flex flex-col flex-wrap">
      <div className="my-2 mb-4">
        <span className="text-left text-md items-start font-medium text-steel-600 mb-5 pb-6">{title}</span>
      </div>
      <span className="py-3 font-normal md:font-medium text-base md:text-2xl leading-normal md:leading-8 text-steel-900">
        {heading}
      </span>

      <div className={`flex flex-row flex-start items-center space-x-3 group my-2'}`}>
        <div className="flex justify-center items-center p-1 box-border bg-white border-2 border-gray-200 shadow-xs rounded-md">
          <Icons.Clock width={18} height={18} />
        </div>
        <p>{time} min</p>
      </div>

      <p className={` text-sm text-steel-900 my-5 w-15 md:w-[280px] pb-15'}`}>{description}</p>
    </div>
  );
};

export default AppointmentInfo;
