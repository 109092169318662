/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookSlotMutationVariables = Types.Exact<{
  data: Types.BookSlotInput;
}>;


export type BookSlotMutation = { __typename?: 'Mutation', bookSlot: { __typename?: 'Slot', startTime: string, endTime: string } };


export const BookSlotDocument = gql`
    mutation BookSlot($data: BookSlotInput!) {
  bookSlot(data: $data) {
    startTime
    endTime
  }
}
    `;
export type BookSlotMutationFn = Apollo.MutationFunction<BookSlotMutation, BookSlotMutationVariables>;

/**
 * __useBookSlotMutation__
 *
 * To run a mutation, you first call `useBookSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookSlotMutation, { data, loading, error }] = useBookSlotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBookSlotMutation(baseOptions?: Apollo.MutationHookOptions<BookSlotMutation, BookSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookSlotMutation, BookSlotMutationVariables>(BookSlotDocument, options);
      }
export type BookSlotMutationHookResult = ReturnType<typeof useBookSlotMutation>;
export type BookSlotMutationResult = Apollo.MutationResult<BookSlotMutation>;
export type BookSlotMutationOptions = Apollo.BaseMutationOptions<BookSlotMutation, BookSlotMutationVariables>;