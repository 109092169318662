import { client } from 'libs/apollo';
import { setData, setLoader } from '../../reducers/config';
import { ThunkFn } from '../definitions/types';
import { FetchConfigurationDocument, FetchConfigurationQuery } from 'graphql/query.generated';

const fetchConfig: ThunkFn = (slug: string) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const { data } = await client.query<FetchConfigurationQuery>({
        query: FetchConfigurationDocument,
        variables: {
          slug,
        },
        fetchPolicy: 'network-only',
      });
      dispatch(setData(data?.config));
    } catch (err) {
      console.error(err);
      dispatch(setLoader(false));
    }
  };
};

export { fetchConfig };
