import { EventTypeQuestion } from 'graphql/types';

type EventType = {
  color: string;
  description?: string | undefined;
  duration: number;
  id: string;
  location?: string | undefined | null;
  name: string;
  questions?: Array<EventTypeQuestion>;
  slug: string;
};

const EventCard = ({ name, description, color }: EventType) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row space-x-3 py-3 group">
        <div className={`w-7 h-7 rounded-2xl`} style={{ background: color }}></div>
        <p className="text-xl font-medium text-steel-900 hover:text-steel-600">{name}</p>
      </div>
      <p className="w-3/4 text-sm text-left font-normal text-steel-600">{description}</p>
    </div>
  );
};

export default EventCard;
