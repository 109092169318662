import { useDateFormatter } from '@react-aria/i18n';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { CalendarState } from 'react-stately';

import Icons from '../../assets/icons';
import Button from '../../base/Button';

export interface CalendarHeaderProps {
  state: CalendarState;
  calendarProps: any;
  prevButtonProps: any;
  nextButtonProps: any;
}

export function CalendarHeader({ state, calendarProps, prevButtonProps, nextButtonProps }: CalendarHeaderProps) {
  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: state.timeZone,
  });

  return (
    <div className="flex items-center justify-between py-4 gap-3">
      <VisuallyHidden>
        <h2>{calendarProps['aria-label']}</h2>
      </VisuallyHidden>
      <div>
        <h2 aria-hidden className="flex-1 align-center font-medium text-base text-steel-700 text-center">
          {monthDateFormatter.format(state.visibleRange.start.toDate(state.timeZone))}
        </h2>
      </div>
      <div className="flex gap-4 items-center">
        <Button displayType="text" disabled={prevButtonProps.isDisabled} {...prevButtonProps}>
          <Icons.ChevronLeft className="focus:outline-none active:outline-none outline-none" />
        </Button>

        <Button {...nextButtonProps} disabled={nextButtonProps.isDisabled} displayType="text">
          <Icons.ChevronRight className="focus:outline-none active:outline-none outline-none" />
        </Button>
      </div>
    </div>
  );
}
