import { useRef } from 'react';
import { useCalendarCell, DateValue } from '@react-aria/calendar';
import { CalendarState } from 'react-stately';
import { isSameMonth, CalendarDate } from '@internationalized/date';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';

export interface CalendarCellProps {
  state: CalendarState;
  date: CalendarDate;
  currentMonth: DateValue;
}

export function CalendarCell({ state, date, currentMonth }: CalendarCellProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { cellProps, buttonProps, isSelected, isDisabled, formattedDate } = useCalendarCell({ date }, state, ref);

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td {...cellProps} className={`py-0.5 relative ${isFocusVisible ? 'z-10' : 'z-0'}`}>
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideMonth}
        className={`w-10 h-10 outline-none group
         ${isSelected ? 'bg-primary text-white rounded-full' : ''} ${isDisabled ? 'disabled' : ''}`}
      >
        <div
          className={`w-full h-full rounded-full flex items-center justify-center cursor-pointer  ${
            isDisabled ? 'text-gray-400' : ''
          } ${isFocusVisible ? 'ring-2 group-focus:z-2 ring-primary-600 ring-offset-2' : ''}  ${
            !isSelected && !isDisabled ? 'hover:bg-primary hover:text-white' : ''
          } cursor-default`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
