import { Calendar, TimeZone, classNames } from '@portal/ui';
import { Icons } from '@portal/ui';
import { components } from 'react-select';

type CalendarViewProps = {
  maxDate: string;
  timezone: string;
  onDateChange: any;
  handleTimezoneChange: (value: string) => void;
};

function CalendarView(props: CalendarViewProps) {
  return (
    <div className="flex flex-col items-start bg-white rounded-[8px]">
      <div className="inline-block text-steel-800 my-4  mb-3">
        <Calendar onChange={props.onDateChange} maxValue={props.maxDate} />
        <div className="my-3 flex flex-col items-start">
          <span className="font-inter font-medium text-sm leading-5 text-steel-600">Time Zone</span>
          <div className="flex items-center flex-shrink py-3 justify-between w-full">
            <TimeZone
              id="timezone"
              showDropdownIndicator
              timezone={props.timezone}
              onChange={(value) => props.handleTimezoneChange(value)}
              selectedComponent={({ children, ...props }) => {
                return (
                  <components.Control {...props} className="flex gap-2 !justify-start">
                    <Icons.Globe />
                    <div className="flex">{children}</div>
                  </components.Control>
                );
              }}
              customStyling={{
                valueContainer: () => 'text-sm p-1',
                input: () => '[&>input]:text-red !text-red',
                container: () => 'w-full',
                placeholder: () => 'text-black text-sm ',
                menuList: () => 'bg-white border m-1 p-2 rounded-md ',
                noOptionsMessage: () => 'text-black text-sm p-2',
                indicatorSeparator: () => 'hidden',
                control: () =>
                  'border-none  bg-white border-steel-300 focus:border-steel-300 hover:border-steel-300 !text-base sm:text-sm py-2 rounded-md',
                option: ({ isSelected }) =>
                  classNames(
                    'active:bg-steel-50 hover:text-black hover:bg-steel-50 !text-sm sm:text-sm  cursor-default rounded-md select-none px-3 py-2 active:text-black text-black !cursor-pointer',
                    isSelected ? 'bg-steel-50' : 'bg-white'
                  ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarView;
