import Icons from '../../assets/icons';

type Props = {
  level: 'success' | 'warning' | 'error' | 'info';
  children: React.ReactNode;
};

const classMapping = {
  info: 'text-blue-800 bg-blue-50',
  error: 'text-red-800 bg-red-50',
  success: 'text-green-800 bg-green-50',
  warning: 'text-yellow-800 bg-yellow-50',
};

const iconMapping = {
  info: <Icons.InfoCircle className="w-5 h-5" />,
  error: <Icons.CrossCircle className="w-5 h-5" />,
  success: <Icons.CheckCircle className="w-5 h-5" />,
  warning: <Icons.AlertCircle className="w-5 h-5" />,
};

export const Alert = ({ level, children }: Props) => {
  return (
    <div className={`flex gap-2 p-4 mb-4 text-sm rounded-lg max-w-fit ${classMapping[level]}`} role="alert">
      {iconMapping[level]} {children}
    </div>
  );
};
