import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BookingConfigurationAvailability } from 'graphql/types';

type ConfigState = {
  data: {
    availability: BookingConfigurationAvailability[];
    eventTypes: {
      color: string;
      id: string;
      name: string;
      location?: string | undefined | null;
      duration: number;
      description?: string;
      slug: string;
      dayAvailabilityInFuture: number;
      questions: {
        id: string;
        text: string;
        required: boolean;
      }[];
    }[];
    id: string;
    slug: string;
    tzName: string;
    title: string;
    welcomeMessage: string;
    merchantAccountName: string;
    merchantAccountLogo?: string | undefined | null;
  } | null;
  loading: boolean;
  error: null | string;
};

const initialState: ConfigState = {
  data: {
    availability: [],
    eventTypes: [],
    id: '',
    slug: '',
    tzName: '',
    welcomeMessage: '',
    merchantAccountLogo: '',
    merchantAccountName: '',
    title: '',
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ConfigState['data'] | null | undefined>) => {
      return { data: action.payload ? action.payload : null, loading: false, error: null };
    },
    setLoader: (state, action: PayloadAction<ConfigState['loading']>) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const configReducer = slice.reducer;
export const { setData, setLoader } = slice.actions;
export type { ConfigState };
