import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { configReducer } from './reducers/config';
import { ThunkDispatch } from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, configReducer);

const store = configureStore({
  reducer: {
    config: persistedReducer,
  },
  middleware: [thunk],
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = ThunkDispatch<RootState, any, any>;

export { store };
export type { RootState, AppDispatch };
